import { useCallback, useState } from 'react';

export type UseServiceExecute<
  ResponseType,
  RequestType
  > = {
  service: (data: RequestType) => Promise<ResponseType>
  initialResponseData?: ResponseType
  initialLoading?: boolean
} & CommonUseFetch

export type CommonUseFetch = {
  onSuccess?: () => void
  onError?: () => void
}

const useServiceExecute = <
  ResponseType,
  RequestType
  >({
    service,
    initialResponseData,
    onSuccess,
    onError,
    initialLoading = false,
  }: UseServiceExecute<ResponseType,
  RequestType>) => {
  const [loading, setLoading] = useState(initialLoading);
  const [data, setData] = useState(initialResponseData);
  const onRequest = useCallback(async (params: RequestType) => {
    try {
      setLoading(true);
      const response = await service(params);
      setData(response);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (onError) {
        onError();
      }
    } finally {
      setLoading(false);
    }
  }, [service, onSuccess, onError]);

  return {
    data,
    loading,
    onRequest,
  };
};

export default useServiceExecute;
