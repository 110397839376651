import styled from 'styled-components';


export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center
  width: 500px;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 10px;
  box-shadow: 0 0 2px 0 silver;
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: 14px;
  transition: .25s;
  &:hover {
    background-color: rgb(203, 203, 203);
    transition: .25s;
  }
  margin: 0 0 5px 0;
  gap: 10px;
`

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;
`;