import { Spin } from "antd";
import { FC, useCallback } from 'react';
import { Card, Header,  PageLayout } from "../../common/styled/app.styled";
import { List, LoadingContainer } from "./styled";
import { TestItem } from "./TestItem";
import { useTestList } from './use-test-list';

export const TestList: FC = () => {
  const {tests, navigateToTest, isLoading} = useTestList();

  const onItemClick = useCallback((id: number, testAttempt: boolean) => {
    localStorage.setItem('testAttempt', JSON.stringify(testAttempt));
    navigateToTest(id)
  }, [navigateToTest]);

  const getContent = useCallback(() => {
    if (isLoading) {
      return (
        <LoadingContainer>
          <Spin size='large'/>
        </LoadingContainer>
      );
    }

    if (!tests || !tests.length) {
      return (
        <LoadingContainer>
          <div>Нет доступных тестов</div>
        </LoadingContainer>
      );
    }

    return (
      <List>
        {tests?.map(((test, key) => <TestItem key={key} test={test} onClick={onItemClick}/>))}
      </List>
    )
  }, [isLoading, onItemClick, tests])

  return (
    <PageLayout>
      <Card>
        <Header>Выберите один из доступных тестов:</Header>
        {getContent()}
      </Card>
    </PageLayout>
  )
}
