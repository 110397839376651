import styled from "styled-components";

export const List = styled.div`
  gap: 10px;
  max-height: 70%;
  overflow-y: scroll;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  min-width: 300px;
`;