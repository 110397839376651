import axios, { AxiosRequestConfig } from "axios";

//'http://admin.profvektor33.ru',
export const httpTransport = axios.create({
  baseURL: 'https://admin.profvektor33.ru',
  // baseURL: 'http://localhost:3006',
});

httpTransport.interceptors.response.use((value) => value, async (error) => {
  let errorText = 'Произошла неизвестная ошибка';
  if (!error.response) {
    errorText = 'Произошла сетевая ошибка';
  }
  console.log(errorText);
  throw error;
});

httpTransport.interceptors.request.use((config: AxiosRequestConfig) => {
  const accessToken = localStorage.getItem('accessToken');
  if (config.headers && accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
})
